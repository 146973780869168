import { InjectionToken } from "@angular/core";
import { Configuration } from "src/app/shared/models/configuration.model";

export const APP_CONFIG = new InjectionToken<Configuration>('App Configuration');

export const AppConfiguration: Configuration = {
    general: {
        appName:    '',
        clientName: '',
        currency:   ''
    },
    assets: {
        icon:        '',
        logo:        '',
        background:  '',
        background2: ''
    },
    theme: {
       app: {
            'main-color':      '',
            'secondary-color': '',
            'darkgray':        '',
            'lightgray':       ''
         },
        dvm: {
            'main-color':      '',
            'secondary-color': '',
            'selected-color':  '' 
       }
    },
    venue: {},
    metadata: [],
    membership: {
        RestrictedPriceScale: {},
        RestrictedBuyerType: {}
    },
    exchange: {
        men_id:     '',
        women_id:   ''
    },
    idle: {active: false}
}

export const AppConfig = {
    provide: APP_CONFIG,
    useValue: AppConfiguration
}